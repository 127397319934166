import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Html from "react-html-parser";

const useStyles = makeStyles({
  container: {
    minHeight: document.documentElement.clientHeight - 230,
    "@media (max-width:425px)": {
      minHeight: document.documentElement.clientHeight - 180,
    },
    display: "flex",
    flexDirection: "column",
  },
  frame: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
});

export default function Forms() {
  const classes = useStyles();
  const [form, setForm] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(process.env.REACT_APP_BASEURL + "/form/" + id, {
      method: "get",
      signal: signal,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.embedCode) {
          setForm(data);
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.frame}>
          {form !== null ? Html(form.embedCode) : null}
        </div>
      </Container>
    </>
  );
}
